<template>
  <div id="buttonHeaders">
    <input
      style="display: inline-block"
      type="checkbox"
      data-toggle="switchbutton"
      v-model="modeButton"
      :data-onlabel="`<i class='fa fa-sun'/>`"
      :data-offlabel="`<i class='fa fa-moon'/>`"
      data-onstyle="primary"
      data-offstyle="dark"
    />
    <ButtonTop
      :link="'https://wiki.nlhpc.cl'"
      :target="'_blank'"
      :title="'Wiki NLHPC'"
      :icon="'fab fa-wikipedia-w fa-lg'"
    />
    <ButtonTop
      :target="'_blank'"
      :link="'https://wiki.nlhpc.cl/Generador_Scripts'"
      :title="'Generador de Scripts'"
      :icon="'fas fa-robot fa-lg'"
    />
    <ButtonTop
      :link="'#'"
      :title="'Iniciar visita guiada'"
      :icon="'far fa-lightbulb'"
      @click="openIntroJS()"
    />
    <ButtonTop
      v-if="token"
      :link="'/solicitudes'"
      :title="'Mensajes'"
      :icon="'fas fa-envelope-open fa-lg'"
    />
    <ButtonTop
      id="ssh"
      v-if="token"
      link="#"
      :title="'Consola SSH'"
      :icon="'fas fa-terminal fa-lg'"
      @click="open()"
    />
    <ButtonNotification v-if="token" />
  </div>
</template>

<!-- eslint-disable no-undef -->
<script>
import ButtonNotification from "@/components/ButtonNotification.vue";
import ButtonTop from "@/components/ButtonTop.vue";

export default {
  name: "HeaderButtons",
  components: {
    ButtonNotification,
    ButtonTop,
  },
  data() {
    return {
      modeButton: true,
    };
  },

  props: {
    token: {
      type: String,
      default: null,
    },
    fromFather: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    modeButton(newValue) {
      let value = newValue ? "light" : "dark";
      this.changeMode(value);
    },
    fromFather() {
      this.modeButton = this.modeButton ? false : true;
    },
  },

  methods: {
    async changeMode(theme) {
      // data-bs-theme="dark"
      document.documentElement.setAttribute("data-theme", theme);
      // document.documentElement.setAttribute("data-bs-theme", theme);
      localStorage.setItem("_theme", theme);
      window.dispatchEvent(new Event("storage"));
    },

    open() {
      let url = `https://ssh.dashboard.nlhpc.cl/?username=${this.token}`;
      let target = "_blank";
      let width = screen.width / 2;
      let height = screen.height / 2;
      let location = `left=${screen.width / 2},top=${screen.height / 2}`;
      let size = `width=${width},height=${height}`;
      if (screen.width <= 1024) size = "fullscreen=1";
      let options = `${location},${size},resizable=1,status=0,toolbar=0,menubar=0,scrollbars=1,titlebar=0`;

      window.open(url, target, options);
    },

    openIntroJS() {
      $("#modalIntroJS").modal("show");
    },

    openScript() {
      $("#modalScript").modal("show");
    },
  },

  mounted() {
    let modeOS = window.matchMedia("(prefers-color-scheme:dark)").matches
      ? "dark"
      : "light";
    let mode = localStorage.getItem("_theme") || modeOS;
    this.modeButton = mode == "light" ? true : false;
    this.changeMode(mode);
  },
};
</script>
