/* eslint-disable no-undef */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "",
    component: () => import("../views/DashboardView.vue"),
    meta: { title: "Dashboard" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
    meta: { title: "Dashboard" },
  },
  {
    path: "/recursos",
    name: "recursos",
    component: () => import("../views/RecursosView.vue"),
    meta: { title: "Recursos" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: { title: "Ingresar" },
  },
  {
    path: "/noticias",
    name: "noticias",
    component: () => import("../views/NoticiasView.vue"),
    meta: { title: "Noticias" },
  },
  {
    path: "/solicitudes",
    name: "solicitudes",
    component: () => import("../views/SolicitudesView.vue"),
    meta: { title: "Solicitudes" },
  },
  {
    path: "/administracion",
    name: "administracion",
    component: () => import("../views/AdministracionView.vue"),
    meta: { title: "Administracion" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
    meta: { title: "Profile" },
  },
  {
    path: "/consumo/:jobid?",
    name: "consumo",
    component: () => import("../views/ConsumoView.vue"),
    meta: { title: "Consumo" },
  },
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("../views/RecoveryView.vue"),
    meta: { title: "Recovery" },
  },
  {
    path: "/recuperar_/:token",
    name: "recuperar_",
    component: () => import("../views/RecoveryView.vue"),
    meta: { title: "Validación" },
  },
  {
    path: "/investigador_responsable/:token",
    component: () => import("../views/IngresoIR.vue"),
    meta: { title: "Inicio Investigador Responsable" },
  },
  {
    path: "/:notFound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/testing",
    name: "testing",
    component: () => import("../views/TestingView.vue"),
    meta: { title: "Testing" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - Laboratorio Nacional de Computación de Alto Rendimiento NLHPC`;
  next();
});

export default router;
