/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";

import { Vue3BsPaginate } from 'vue3-bootstrap-paginate';

import IdleVue from "idle-vue";
import emitter from "tiny-emitter/instance";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp({ render: () => h(App) });

app.component('Vue3BsPaginate', Vue3BsPaginate);

app.use(router);
app.use(VueSweetalert2);
app.use(CKEditor);

let user = JSON.parse(localStorage.user || null);

if (user && !user.admin) {
  app.use(IdleVue, {
    eventEmitter: {
      $on: (...args) => emitter.on(...args),
      $once: (...args) => emitter.once(...args),
      $off: (...args) => emitter.off(...args),
      $emit: (...args) => emitter.emit(...args),
    },
    idleTime: 30 * 60 * 1000, // 30 minutos = Cierre de sesión por inactividad
  });
}

app.mount("#app");
