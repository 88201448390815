/* eslint-disable no-undef */
document.addEventListener("DOMContentLoaded", function () {
    const showNavbar = (toggleId, navId) => {
        const toggle = document.getElementById(toggleId);
        let nav = document.getElementById(navId);
        let left_col = $(".left-side-column");
        let main_col = $(".main-column");
        let icono = document.getElementsByClassName("fas")[0];

        if (toggle && nav) {
            toggle.addEventListener('click', () => {
                nav.classList.toggle('show')
                toggle.classList.toggle('bx-x')
                icono.classList.toggle('fa-bars')
                icono.classList.toggle('fa-arrow-left')
                console.log(icono.classList.value)
                // let main_curr_width = main_col.width()
                let left_curr_width = $('.nlhpc-sidebar').width()
                console.log(`main_curr_width: ${left_curr_width}`)
                let window_width = window.innerWidth
                let main_window_state = icono.classList.value == "fas fa-arrow-left";

                if (window_width >= 612) {
                    var toggleWidth_left = main_window_state ? "223.031px" : "68px";
                    left_col.animate({
                        width: toggleWidth_left,
                        duration: 300,
                        easing: "linear"
                    });
                    main_col.animate({
                        paddingLeft: toggleWidth_left,
                        duration: 300,
                        easing: "linear"
                    });
                }

                console.log(`main_curr_width: ${left_curr_width}`)
            })
        }
    }

    showNavbar('nlhpc-navbar-toggler', 'nav-bar')
});