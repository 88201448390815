<template>
  <a
    class="buttonTop"
    :href="link"
    :target="target"
    aria-current="page"
    :title="title"
  >
    <i :class="icon"></i>
  </a>
</template>

<script>
export default {
  name: "ButtonTop",
  props: {
    link: {
      type: String,
      default: "https:/",
    },
    target: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.buttonTop {
  margin-top: 2px;
  width: 39px;
  height: 39px;
  display: inline-block;
  padding: 7px;
  border-radius: 8px;
  background: var(--azul-nlhpc-dark);
  color: white;
  margin-left: 10px;
  align-items: center;
  justify-items: center;
  text-align: center;
}
.buttonTop:hover {
  background: var(--plomo-claro);
  transition: 0.5s;
}
</style>
