<template>
  <ul
    class="nav col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-1 justify-content-center text-small pe-1 pe-sm-4 ps-0"
  >
    <li
      data-toggle="dropdown"
      :data-bs-toggle="user ? 'dropdown' : ''"
      aria-expanded="true"
    >
      <a
        :href="!user ? '/login' : '#'"
        class="vert-centered nav-link text-white pe-0 ps-0"
      >
        <div
          class="bi d-block mx-auto mb-0 nlhpc-numberCircle"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          :title="user ? user.Nombre : ''"
        >
          <span v-if="user">{{ user.Nombre.substring(0, 1) }}</span>
          <i v-else class="fas fa-user-alt fa-xs"></i>
        </div>

        <ul v-if="user" class="dropdown-menu dropdown-menu-end mt-0 me-2">
          <li class="dropdown-item">
            <b style="font-size: large">{{ user.Nombre }}</b>
            <br />
            <span><b>Usuario : </b>{{ user.usuario }}</span>
          </li>
          <li class="break">
            <hr class="my-1 ms-2" style="margin-right: 0.5rem !important" />
          </li>
          <li class="dropdown-item text-start" @click="goToUrl('/profile')">
            <a @click="goToUrl('/profile')"> Ajustes de perfil </a>
          </li>
          <li class="break">
            <hr class="my-1 ms-2" style="margin-right: 0.5rem !important" />
          </li>
          <li class="dropdown-item" @click="unLogin()">
            <div class="fw-bold vertodas text-center">
              <a href="#" @click="unLogin()"> Cerrar sesión </a>
            </div>
          </li>
        </ul>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "HeaderLogin",
  data() {
    return {
      user: null,
      token: null,
    };
  },

  created() {
    this.token = localStorage.token;
  },

  mounted() {
    this.user = JSON.parse(localStorage.user || false);
    window.addEventListener("storage", () => {
      this.fixDropdowns();
    });
  },

  updated() {
    this.token = localStorage.token;
    this.fixDropdowns();
  },

  methods: {
    async unLogin() {
      this.$swal({
        title: "¿Realmente desea cerrar su sesión?",
        showCancelButton: true,
        confirmButtonText: "Si, cerrar",
        cancelButtonText: `Cancelar`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          location.href = "/";
        }
      });
    },

    fixDropdowns() {
      this.theme = localStorage._theme;
      const dropdowns = document.querySelectorAll(".dropdown-menu");
      dropdowns.forEach((item) => {
        if (this.theme == "dark") item.classList.add("dropdown-menu-dark");
        else item.classList.remove("dropdown-menu-dark");
      });
    },

    goToUrl(url) {
      location.href = url;
    },
  },
};
</script>
