<template>
  <nav id="nlhpc-header" class="navbar navbar-expand-md fixed-top">
    <!-- Vista de escritorio -->
    <div class="container-fluid">
      <a class="navbar-brand nlhpc-logo2 nav-top-fixed" href="/"></a>
      <div class="align-items-center d-flex d-none d-md-flex">
        <HeaderButtons
          :fromFather="modeTheme"
          :token="token"
          style="
            text-align: right;
            margin-right: 1vh;
            float: right;
            min-width: 500px;
          "
        />
        <HeaderLogin v-if="token" class="col text-center" />
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style="border: 1px solid var(--negro); padding: 10px"
      >
        <i class="navbar-toggler-icon2 fa-solid fa-chevron-down"></i>
      </button>
    </div>

    <!-- Vista movil -->
    <div class="container-fluid d-sm-flex d-md-none">
      <div class="row" style="width: 100%">
        <div class="col-12 collapse text-start" id="navbarNav">
          <ul class="navbar-nav my-3">
            <li class="break">
              <hr />
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" v-on:click="changeMode()">
                <span v-if="modeTheme" style="color: var(--negro)">
                  <i class="fas fa-moon"></i> Modo oscuro
                </span>
                <span v-else style="color: var(--negro)">
                  <i class="fas fa-sun"></i> Modo claro
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                href="https://wiki.nlhpc.cl"
                style="color: var(--negro)"
              >
                <i class="fab fa-wikipedia-w"></i> Wiki NLHPC
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://wiki.nlhpc.cl/Generador_Scripts"
                style="color: var(--negro)"
              >
                <i class="fas fa-robot"></i> Generador de Script
              </a>
            </li>
            <li class="nav-item" v-if="token">
              <a class="nav-link" href="" style="color: var(--negro)">
                <i class="fas fa-envelope-open"></i> Mensajes
              </a>
            </li>
            <li class="nav-item" v-if="token">
              <a
                class="nav-link"
                href="#"
                @click="openSSH()"
                style="color: var(--negro)"
              >
                <i class="fas fa-terminal"></i> Consola
              </a>
            </li>
            <li class="nav-item" v-if="token">
              <a class="nav-link" href="" style="color: var(--negro)">
                <i class="fas fa-bell"></i>
                Notificaciones
              </a>
            </li>
            <li class="break">
              <hr />
            </li>
            <li class="nav-item" v-if="token">
              <a class="nav-link" href="" style="color: var(--negro)">
                <i class="fas fa-user"></i> Perfil de usuario
              </a>
            </li>
            <!-- <li class="nav-item" v-else>
              <a class="nav-link" href="/login" style="color: var(--negro)">
                <i class="fas fa-user"></i> Iniciar sesión
              </a>
            </li> -->
            <li class="nav-item" v-if="token">
              <a
                class="nav-link"
                href="#"
                v-on:click="closeSession()"
                style="color: var(--negro)"
              >
                <i class="fas fa-sign-out-alt"></i> Cerrar sesión
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<!-- eslint-disable no-undef -->
<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import HeaderButtons from "@/components/HeaderButtons.vue";

export default {
  name: "MainHeader",
  components: {
    HeaderLogin,
    HeaderButtons,
  },
  data() {
    return {
      modeTheme: true,
    };
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  methods: {
    closeSession() {
      window.localStorage.removeItem("token");
      location.href = "/";
    },

    changeMode() {
      this.modeTheme = this.modeTheme ? false : true;
    },

    openSSH() {
      let url = `https://ssh.dashboard.nlhpc.cl/?username=${this.token}`;
      let target = "_blank";
      let width = screen.width / 2;
      let height = screen.height / 2;
      let location = `left=${screen.width / 2},top=${screen.height / 2}`;
      let size = `width=${width},height=${height}`;
      if (screen.width <= 1024) size = "fullscreen=1";
      let options = `${location},${size},resizable=1,status=0,toolbar=0,menubar=0,scrollbars=1,titlebar=0`;

      window.open(url, target, options);
    },

    openScript() {
      $("#modalScript").modal("show");
    },
  },
  created() {
    let theme = localStorage._theme;
    this.modeTheme = theme == "light" ? true : false;
  },
};
</script>

<style scoped>
.navbar-toggler-icon2 {
  color: var(--negro);
}
</style>
