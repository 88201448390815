<template>
  <div class="left-side-column" v-if="token">
    <div id="nav-bar" class="nlhpc-sidebar l-navbar">
      <nav class="nav">
        <button
          class="nlhpc-ham cruz-nav col-2 navbar-toggler me-5"
          id="nlhpc-navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarToggleExternalContent2"
          aria-controls="navbarToggleExternalContent2"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style="margin-top: 60px"
        >
          <i class="fas fa-bars"></i>
        </button>
        <div class="col-10">
          <div class="nav_list" style="margin-top: 60px">
            <a href="#" class="nav_link">
              <span class="mb-4"></span>
            </a>
            <router-link
              to="/"
              :class="{
                nav_link: true,
                active: routePath == '/',
              }"
            >
              <i class="fas fa-tachometer-alt nav_icon"></i>
              <span class="nav_name">Dashboard</span>
            </router-link>
            <!-- <router-link
              to="recursos"
              :class="{
                nav_link: true,
                active: routePath.includes('recursos'),
              }"
            >
              <i class="fas fa-layer-group nav_icon"></i>
              <span class="nav_name">Recursos</span>
            </router-link> -->
            <router-link
              to="/noticias"
              :class="{
                nav_link: true,
                active: routePath.includes('noticias'),
              }"
            >
              <i class="fas fa-bullhorn nav_icon"></i>
              <span class="nav_name">Anuncios</span>
            </router-link>
            <router-link
              to="/solicitudes"
              :class="{
                nav_link: true,
                active: routePath.includes('solicitudes'),
              }"
            >
              <i class="fas fa-pencil nav_icon"></i>
              <span class="nav_name">Solicitudes</span>
            </router-link>
            <router-link
              v-if="user?.admin"
              to="/administracion"
              :class="{
                nav_link: true,
                active: routePath.includes('administracion'),
              }"
            >
              <i class="fa-solid fa-gear nav_icon"></i>
              <span class="nav_name">Administracion</span>
            </router-link>
          </div>
          <!-- <br /><br />
          <div class="nav_list">
            <a href="#" class="nav_link">
              <i class="fas fa-lock nav_icon"></i>
              <span class="nav_name">Admin 1</span>
            </a>
            <a href="#" class="nav_link">
              <i class="fas fa-lock nav_icon"></i>
              <span class="nav_name">Admin 2</span>
            </a>
          </div> -->
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import "@/assets/css/sidebar.css";
import "@/assets/js/sidebar.js";

export default {
  name: "MainSidebar",
  data() {
    return {
      user: null,
    };
  },

  props: {
    token: {
      type: String,
      default: null,
    },
  },

  computed: {
    routePath() {
      return this.$route.path;
    },
  },

  mounted() {
    this.user = JSON.parse(localStorage.user || null);
  },
};
</script>
