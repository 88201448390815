<template>
  <footer
    class="text-center container-fluid"
    style="border-top: 5px solid var(--footer-line)"
  >
    <div class="row py-5 text-md-start text-center">
      <div class="col-2"></div>
      <div class="col-12 col-md-2 mb-4 mb-md-0 me-5 nlhpc-logocolor">
        <img />
      </div>
      <div class="col-12 col-md-6 row nlhpc-columnasfooter">
        <div class="col-6 col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5>Nosotros</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/acerca/">
                Acerca de NLHPC
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/acerca/equipo/">
                Equipo
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/acerca/socios/">
                Socios
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/publicaciones/">
                Publicaciones
              </a>
            </li>
          </ul>
        </div>

        <div class="col-6 col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5>Servicios</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/servicios/">
                Resumen de Servicios
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.nlhpc.cl/servicios/servicios-instituciones-publicas/"
              >
                Servicios para Instituciones Públicas
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.nlhpc.cl/servicios/servicios-industria/"
              >
                Servicios para la Industria
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.nlhpc.cl/servicios/servicios-academia/"
              >
                Servicios para la Academia
              </a>
            </li>
          </ul>
        </div>

        <div class="col-6 col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5>Más</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/infraestructura/">
                Infraestructura
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/noticias/">
                Noticias
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/eventos/">
                Eventos
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/visitas/">
                Visitas
              </a>
            </li>
          </ul>
        </div>

        <div class="col-6 col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5>Información</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/contacto/">
                Contacto
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/soporte/">
                Soporte
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.nlhpc.cl/prensa/">
                Prensa
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row text-center p-3 nlhpc-footer2">
      <div class="col-12 col-lg-2"></div>
      <div class="col-12 col-lg-6 nlhpc-copyright text-white mb-2">
        <span class="d-none d-lg-block">
          2023 Laboratorio Nacional de Computación de Alto Rendimiento /
          National Laboratory for Performance High Computing - Chile
        </span>
        <span class="d-block d-lg-none">
          2023 Laboratorio Nacional de Computación de Alto Rendimiento <br />
          National Laboratory for Performance High Computing - Chile
        </span>
      </div>
      <div class="col-12 col-lg-2">
        <a
          class="btn btn-outline-light btn-floating m-1"
          target="_blank"
          href="https://twitter.com/nlhpc"
          role="button"
        >
          <i class="fab fa-twitter"></i>
        </a>
        <a
          class="btn btn-outline-light btn-floating m-1"
          target="_blank"
          href="https://www.youtube.com/channel/UCYTsHIse10rpXHFlmGu-nEQ"
          role="button"
        >
          <i class="fab fa-youtube"></i>
        </a>
        <a
          class="btn btn-outline-light btn-floating m-1"
          target="_blank"
          href="https://www.linkedin.com/company/nlhpc/"
          role="button"
        >
          <i class="fab fa-linkedin-in"></i>
        </a>
      </div>
      <div class="col-12 col-lg-2"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>
