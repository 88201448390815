<template>
  <div
    class="buttonTop position-relative"
    data-toggle="dropdown"
    data-bs-toggle="dropdown"
    aria-current="page"
  >
    <a
      href="#"
      data-toggle="dropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fas fa-bell fa-lg" style="color: white"></i>
      <span
        v-if="qty"
        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
      >
        {{ qty }}
      </span>
    </a>

    <ul class="dropdown-menu dropdown-menu-end mt-3">
      <li>
        <div class="dropdown-item fw-bold">Notificaciones</div>
        <hr class="my-1 ms-2" />
      </li>
      <li v-for="(item, index) in notifications" :key="item.id">
        <a :href="item.url">
          <button
            class="dropdown-item"
            type="button"
            @click="noticiaModal(item, index)"
          >
            {{ item.subject }}
          </button>
          <div class="text-end container notifica-lapse">
            {{ item.fecha }}
          </div>
        </a>
        <hr class="my-1 ms-2" />
      </li>
      <li>
        <div class="dropdown-item fw-bold vertodas text-center">
          <a class="notifica-todas" @click="goToURL('/noticias')">
            Ver todas
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<!-- eslint-disable no-undef -->

<script>
import axios from "axios";

export default {
  name: "ButtonNotification",
  data() {
    return {
      qty: 0,
      notifications: [],
    };
  },

  async created() {
    this.getNotifications();
  },

  methods: {
    async getNotifications() {
      let API_BASE_URL = process.env.VUE_APP_API_URL;
      const options = {
        method: "POST",
        url: `${API_BASE_URL}/user/alertas_enviadas`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.getToken()}`,
        },
        data: { username: this.getUser().usuario },
      };

      let request = await axios.request(options);

      if (request.status === 200) {
        this.notifications = request.data.alertas;
        this.qty = Object.keys(this.notifications).length;
      }
    },

    async setNotificationSeen(index) {
      let API_BASE_URL = process.env.VUE_APP_API_URL;
      const options = {
        method: "POST",
        url: `${API_BASE_URL}/user/alertas_vista`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.getToken()}`,
        },
        data: { id_alerta: index },
      };

      let request = await axios.request(options);

      if (request.status === 200) {
        this.getNotifications();
      }
    },

    goToURL(url) {
      location.href = url;
    },

    getToken() {
      this.token = localStorage.token;
      return this.token;
    },

    getUser() {
      return JSON.parse(localStorage.user || null);
    },

    async noticiaModal(item, index) {
      let content = document.createElement("div");
      content.innerHTML = item.cuerpo;
      content.style.textAlign = "left";
      content.style.fontWeight = "normal";
      content.style.fontSize = "small";
      content.querySelector("img").parentElement.remove();

      await this.setNotificationSeen(index);

      this.$swal({
        title: `<small>${item.subject}</small>`,
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        html: content,
        customClass: {
          confirmButton: "btn btn-secondary",
        },
      });
    },
  },
};
</script>

<style scoped>
.buttonTop {
  width: 39px;
  height: 39px;
  display: inline-block;
  padding: 7px;
  border-radius: 8px;
  background: var(--azul-nlhpc-dark);
  color: white;
  margin-left: 10px;
  margin-right: 20px;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.buttonTop:hover {
  background: var(--plomo-claro);
}

a {
  text-decoration: none;
}

.dropdown-item {
  cursor: pointer;
}
</style>
