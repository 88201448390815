<template>
  <div>
    <div v-if="isLoading" class="loading-container">
      <div class="loader"></div>
    </div>
    <div :class="{ 'main-column-master': true, blur: isLoading }">
      <MainSidebar :token="token" />
      <MainHeader :token="token" />
      <br /><br /><br />
      <div id="nlhpc-master">
        <router-view />
      </div>
      <MainFooter />
    </div>

    <div
      class="modal fade"
      id="modalIntroJS"
      tabindex="-1"
      aria-labelledby="modalIntroJSLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-light">
          <div class="modal-body" style="color: black">
            <div class="row">
              <div class="col text-center">
                <img
                  class="mb-2"
                  :src="require('@/assets/img/logocolor.png')"
                  width="200"
                />
              </div>
            </div>
            <hr />
            <div class="container">
              <div class="row">
                <div class="col">
                  <h4>
                    <b>NLHPC Dashboard 2.0</b>
                  </h4>
                  <p
                    style="
                      text-align: justify;
                      padding-left: 10px;
                      padding-right: 10px;
                    "
                  >
                    ¡Bienvenidos(as) al Dashboard del Laboratorio Nacional de
                    Computación de Alto Rendimiento de la Universidad de Chile!
                  </p>
                  <p
                    style="
                      text-align: justify;
                      padding-left: 10px;
                      padding-right: 10px;
                    "
                  >
                    A continuación lo invitamos a realizar un tour guiado, donde
                    les mostraremos paso a paso cómo navegar por la nueva
                    interfaz y acceder a todas sus funcionalidades.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="px-4 pb-3">
            <div class="row">
              <div class="col-5 text-start">
                <label style="color: black; cursor: pointer; margin-top: 6px">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    v-model="inputDontShow"
                  />
                  <small
                    class="text-decoration-underline"
                    style="color: black; font-size: small"
                  >
                    No volver a mostrar
                  </small>
                </label>
              </div>
              <div class="col-7 text-end">
                <a
                  class="text-decoration-underline"
                  data-bs-dismiss="modal"
                  style="
                    color: black;
                    font-size: small;
                    margin-right: 25px;
                    cursor: pointer;
                  "
                >
                  Quizás más tarde
                </a>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showIntroJS()"
                  data-bs-dismiss="modal"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable no-undef -->
<script>
import "@/assets/css/charts.css";
import "@/assets/css/animate.css";
import "@/assets/css/style.css";
import "@/assets/css/introjs.css";
import "@/assets/css/switchbutton.css";
import "@/assets/js/switchbutton.js";

import MainSidebar from "@/components/MainSidebar.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    MainSidebar,
    MainHeader,
    MainFooter,
  },

  data() {
    return {
      token: String,
      isLoading: true,
      user: null,
      inputDontShow: false,
      activeToken: null,
    };
  },

  watch: {
    inputDontShow(newValue) {
      localStorage.introjsComplete = newValue;
    },
  },

  created() {
    this.token = localStorage.token || null;
    this.isLogin();
    this.renewToken();
  },

  updated() {
    this.isLogin();
    this.renewToken();
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
      this.runIntroJS();
    }, 1000);
    this.user = JSON.parse(localStorage.user || null);

    setInterval(() => {
      this.renewToken();
    }, 20 * 60 * 1000);
  },

  onIdle() {
    this.closeSession();
  },

  onActive() {},

  methods: {
    isLogin() {
      this.token = window.localStorage.getItem("token");
      if (process.env.VUE_APP_MODE === "development")
        this.token
          ? console.log("Estamos logueados!")
          : console.log("Sesión cerrada!");
    },

    runIntroJS() {
      let path = this.$route.path;
      if (path.includes("dashboard") || path == "/") {
        if (window.innerWidth >= 800) {
          let cookieFile = localStorage.introjsComplete;
          this.inputDontShow = cookieFile == "true" ? true : false;
          if (!this.inputDontShow) {
            $("#modalIntroJS").modal("show");
          }
        }
      }
    },

    showIntroJS() {
      introJs()
        .setOptions({
          doneLabel: "Continuar",
          prevLabel: "Anterior",
          nextLabel: "Siguiente",
          dontShowAgain: false,
          dontShowAgainLabel: "No volver a mostrar",
          buttonClass: "btn btn-sm btn-primary",
          tooltipClass: "introJSContainer",
          disableInteraction: true,
          steps: [
            {
              element: document.getElementById("panalContainerTitle"),
              title: "Título de visualización",
              intro: `El título indica la visualización actual, este título cambiará dependiendo de la selección hecha con los botones de vista CPU-GPU y RAM.`,
            },
            {
              element: document.getElementById("panalContainerSwitch"),
              title: "Botones de visualización",
              intro: `<p>Al presionar estos botones podrá seleccionar que tipo de información visualizar en el panal de nodos de cómputo.</p>
              <b>CPU: </b> Consumo de CPU<br>
              <b>GPU: </b> Consumo de GPU<br>
              <b>CPU-RAM: </b> Consumo de RAM en nodos de CPU<br>
              <b>GPU-RAM: </b> Consumo de RAM en nodos de GPU.`,
            },
            {
              element: document.getElementById("PanalContainerPartition"),
              title: "Particiones de nodos de cómputo",
              intro: `<p>La barra de navegación permite seleccionar una visión general o específica de cada partición disponible en el clúster.</p>`,
            },
            {
              element: document.getElementById("containerPanal"),
              title: "Panal de nodos de cómputo",
              intro: `<p>El panal muestra los nodos de cómputo según la partición seleccionada.</p><p>Cada hexágono representa un nodo de cómputo y <b>es posible obtener más información al hacer click en cada uno de ellos (siguiente ventana)</b>.</p>`,
              position: `right`,
            },
            {
              title: "Modal Nodo",
              intro: `<div class="row"><div class="col-12">
                <img style="width:100%;" src="${require("@/assets/img/modal-nodo-cpu.png")}" />
                </div><div class="col-12">
                <p><br/>Despliega la cantidad de CPUs reservadas, total de CPUs disponibles en el nodo y porcentaje de uso, además se visualiza un gráfico de carga con intervalos de 1 minuto.</p>
                </div></div>`,
            },
            {
              title: "Modal Nodo",
              intro: `<div class="row"><div class="col-12">
                <img style="width:100%;" src="${require("@/assets/img/modal-nodo-ram.png")}" />
                </div><div class="col-12">
                <p><br/>Despliega la cantidad de memoria RAM reservada, total de memoria RAM disponible en el nodo y porcentaje de uso, además se visualiza un gráfico de uso de memoria con intervalos de 1 minuto.</p>
                </div></div>`,
            },
            {
              element: document.getElementById("panalContainerFooter"),
              title: "Mapa de colores",
              intro: `El mapa de colores permite identificar el nivel de uso y estado de cada nodo.`,
              position: "right",
            },
            {
              element: document.getElementById("dashboardTacometer"),
              title: "Tacómetro de CPU",
              intro: `Permite conocer gráficamente el porcentaje de CPUs reservadas en el clúster.`,
              position: "left",
            },
            {
              element: document.getElementById("dashboardClusterBar"),
              title: "Estado del Clúster",
              intro: `Permite conocer la cantidad de CPUs y memoria RAM reservada, total y disponible en el clúster.`,
              position: "left",
            },
            {
              element: document.getElementById("dashboardClusterTable"),
              title: "Tabla de resumen de particiones",
              intro: `La tabla de resumen proporciona una visión detallada de cada partición, identificando las tareas en ejecución, las tareas en espera, la cantidad de nodos libres, la cantidad de CPU y memoria disponible según cada partición. Esta información es esencial para un monitoreo efectivo de los recursos y la gestión de tareas en el clúster`,
              position: "bottom",
            },
            {
              element: document.getElementById("carouselNews"),
              title: "Noticias y comunicados",
              intro: `Aquí encontrará información actualizada sobre eventos, cursos y avisos importantes dirigidos a nuestra comunidad NLHPC. `,
              position: "bottom",
            },
            {
              element: document.getElementById("buttonHeaders"),
              title: "Menú de navegación",
              intro: `Aquí encontrará accesos directos a nuestra Wiki y al Generador de Scripts, como también un botón para cambiar entre estilo claro/oscuro en el nuevo dashboard.`,
              position: "left",
            },
          ],
        })
        .onafterchange((data) => {
          let buttonBefore = document.querySelector(".introjs-prevbutton");
          if (data.id == "panalContainerTitle") buttonBefore.hidden = true;
          else buttonBefore.hidden = false;
        })
        .onbeforechange(async (data) => {
          let width = window.innerWidth;
          let element = document.querySelectorAll(".introJSContainer");

          if (data?.className?.includes("introjsFloatingElement")) {
            if (width >= 800)
              element.forEach((item) => (item.style.minWidth = "800px"));
            else
              element.forEach((item) => (item.style.minWidth = `${width}px`));
          } else {
            let modal = document.querySelector(".introJSContainer");
            if (modal) {
              modal.style.minWidth = "300px";
            }
          }
        })
        .start();
    },

    async renewToken() {
      try {
        if (!this.token) return;
        const API_BASE_URL = process.env.VUE_APP_API_URL;
        const options = {
          method: "POST",
          url: `${API_BASE_URL}/login/renew_token`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        };

        let request = await axios.request(options);

        if (request.status == 200) {
          localStorage.token = request.data.accessToken;
          this.token = localStorage.token;
          return;
        }

        if (request.status == 202) {
          return;
        }

        this.closeSession("Se ha cerrado la sesión por seguridad.");
      } catch (error) {
        if (process.env.VUE_APP_MODE === "development")
          console.log("Error al renovar token", error);
        this.closeSession();
      }
    },

    closeSession(msg = false) {
      if (!this.token) return;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.token = null;

      let message =
        msg ||
        "Su sesión ha sido cerrada por inactividad, vuelva a iniciar sesión";

      this.$swal({
        title: "Sesión cerrada",
        text: message,
        icon: "info",
        confirmButtonColor: "red",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) location.href = "/login";
      });
    },
  },
};
</script>

<style>
.introJSContainer {
  min-width: 300px;
  min-height: 200px;
}
</style>
